.navMenu {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.logo {
  height: 30px;
}

.navLink {
  height: 100%;
  margin-left: 40px;
  text-decoration: none;
}

.navBtn {
  height: 100%;
}

.navBar {
  background-color: #ffffff;
}

.currencySelect {
  margin-right: 15px;
}

.currencySelect__input {
  min-width: 100px;
}

.currencyTimestamp {
  margin-right: 15px;
  height: 40px;
  margin-top: -2px;
}

.currencyTimestamp__label {
  color: #5a676e;
  font-size: 12px;
  text-align: center;
}

.currencyTimestamp__value {
  color: #303334;
  font-weight: 600;
  text-align: center;
}
