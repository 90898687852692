.container {
  padding: 10px 30px 30px;
}

.backButton {
  color: #a4a9ab;
}

.backButton__label {
  font-size: 13px;
}

.basics {
  padding: 20px;
  margin-bottom: 30px;
}

.section {
  width: 60%;
  margin-bottom: 30px;
}

.section__title {
  font-size: 20px;
}

.tableSection {
  margin-bottom: 30px;
}

.fileName {
  font-size: 15px;
  color: #5a676e;
  text-transform: none;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
