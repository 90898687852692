.card__content {
  padding: 0;
}

.card__content:last-child {
  padding-bottom: 0;
}

.card__section {
  padding: 15px;
  border-left: 1px solid #f1f1f1;
}

.card__section:first-of-type {
  border-left: none;
}

.statistic__label {
  font-size: 12px;
  font-weight: 400;
  color: #5a676e;
}

.statistic__value {
  font-size: 15px;
  font-weight: 600;
  color: #303334;
}

.subheader__item {
  margin-left: 5px;
}

.subheader__id {
  font-size: 12px;
  font-weight: 600;
  color: #303334;
}

.header {
  border-bottom: 1px solid #f1f1f1;
}

.header__button {
  margin-left: 10px;
}

.kpi__title {
  font-weight: 600;
  margin-top: 10px;
}

.tabs__container {
  min-height: 320px;
  flex-grow: 1;
  display: flex;
  border-top: 1px solid #f1f1f1;
}

.tabs__panel {
  width: 100%;
  padding: 20px;
}

.validationError {
  color: #d55050;
  font-size: 12px;
}

.modal__btnClose {
  margin-top: -29px;
}

.ndaModal__btnClose {
  margin-top: -16px;
}

.ndaModal__downloadBtn {
  min-width: 150px;
  align-self: center;
  justify-self: start;
}

.ndaModal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__actions {
  padding: 0;
}

.modal__content {
  display: flex;
  height: 100%;
}

.modal__btnSubmit {
  border-radius: 0 0 8px 8px;
  height: 60px;
}

.modal__form__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal__priceInput {
  flex-grow: 1;
}

.modal__label {
  font-size: 20px;
  color: #303334;
}

.modal__minBid {
  font-size: 13px;
  color: #a4a9ab;
}

.modal__bidFormControl {
  border: none;
}

.modal__bidInput {
  font-size: 20px;
  height: 50px;
  color: #303334;
  text-align: center;
  font-weight: 600;
  border: 2px solid #f1f1f1;
  outline: 0;
}

.modal__switchLabel {
  font-size: 12px;
}

.modal__confirmationText {
  font-size: 18px;
  margin-bottom: 20px;
}

.tabs {
  width: 25%;
}

.tabs__tab {
  font-size: 12px;
}

.tabs__tab__wrapper {
  align-items: start;
}
