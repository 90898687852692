@use '../shared/scss/base/variables' as v;

.main {
  margin: 30px 10%;
  width: 100%;
  height: 100%;
}

.tabs__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 30px;
}

.tabs__backBtn {
  min-width: 200px;
}

.tabs {
}

.tabs__tab {
  width: 180px;
  font-size: 15px;
  font-weight: 600;
  border: none;
}

.tabContent {
  min-height: 200px;
  width: 100%;
  position: relative;
}

.tabContent__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 50px;
}

.tabContent__actionBtn {
  min-width: 135px;
}

.tabContent__tableContainer {
  padding: 30px 50px;
  width: calc(100% - 100px);
}

.tabContent__table {
  margin-bottom: 20px;
}

.tabContent__table__actionBtn {
  margin-left: 10px;
  padding: 0 8px;
  height: 30px;
  min-width: 123px;
}

.modal__header {
  border-bottom: 1px solid #f1f1f1;
}

.modal__content {
  padding-top: 40px !important;
  padding-bottom: 50px;
}

.modal__actions {
  padding: 0;
}

.modal__btnSubmit {
  border-radius: 0 0 8px 8px;
  height: 60px;
}

.modal__fileRow {
  display: grid;
  grid-template-columns: 30% 1fr 150px;
  column-gap: 20px;
  min-height: 52px;
}

.modal__fileLabel {
  font-size: 15px;
  font-weight: bold;
  min-width: 100px;
  justify-self: start;
  align-self: center;
}

.modal__uploadButton {
  width: 150px;
  grid-column-start: 3;
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
  svg {
    margin-right: 12px;
  }
}

.modal__downloadButton {
  color: #c4c4c4;
  min-width: 150px;
  text-transform: lowercase;
  align-self: center;
  justify-self: start;
}

.modal__downloadButtonLabel {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.statusFilter__select {
  height: 40px;
  width: 216px;
  background-color: white;
}

.statusFilter__select__display {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  &:focus {
    background-color: white;
  }
}

.searchInput {
  background-color: white;
  height: 40px;
  width: 216px;
  margin-right: 20px;
}

.filesSection__container {
  padding: 25px 50px;
}

.filesSection__header {
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
}

.filesSection__actionButton {
  margin-left: 10px;
}

.filesSection__downloadButton {
  padding: 0;
  color: #c4c4c4;
  text-transform: none;
  display: flex;
  justify-content: space-between;
}

.filesSection__tableContainer {
  margin-top: 20px;
  margin-bottom: 30px;
}

.errorList__container {
  position: relative;
}

.errorList__list {
  max-height: 250px;
  overflow-y: scroll;
  list-style-type: disc;
  & .MuiListItem-root {
    display: list-item;
  }
}

.errorList__listItem {
  font-size: 12px;
  list-style-type: disc;
  display: list-item;
}

.errorList__button {
  min-width: unset;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-left: 5px;
  color: #a4a9ab;
  background-color: white;
  &:hover {
    background-color: white;
  }
}

.errorList__buttonContainer {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.errorList__errorCount {
  color: #f6a26c;
}

.statusSelect {
  max-width: 200px;
}

.selectCell {
  width: 200px;
}

.kycSelectDisplay {
  padding: 8px;
  font-weight: 500;
  width: 100%;
  text-align: left;
}

.actionCell {
  display: flex;
  align-items: center;
}

.buyerSearchInput {
  background-color: white;
  height: 40px;
  width: 200px;
  margin-right: 20px;
}

.buyerFilter__select {
  height: 40px;
  width: 200px;
  background-color: white;
  border-radius: 4px;
  box-sizing: content-box;
}

.buyerFilter__groupOption {
  padding-left: 25px;
}

.publishButton {
  min-width: 120px;
  margin-right: 18px;
}

.portfolioLink {
  color: inherit;
  text-decoration: none;
}

.formSection__title {
  padding-top: 15px;
  padding-bottom: 15px;
}

.groupNameInput {
  padding: 0;
  display: flex;
  justify-content: center;

  input {
    padding: 4px 8px;
  }
}

.groupTableContainer {
  max-height: 500px;
}

.logFilters__container {
  width: 100%;
  padding: 10px 50px 0;
}

.logFilters__label {
  font-size: 20px;
  font-weight: 500;
}

.logFilters__controls {
  display: flex;
  gap: 30px;
}
