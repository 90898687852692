.title {
  font-weight: 400;
}

.portfolioCount {
  color: #a4a9ab;
  font-weight: 400;
  margin-left: 10px;
}

.statusFilter__select {
  height: 40px;
  width: 100px;
  background-color: white;
  margin-right: 20px;
}

.statusFilter__select__display {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  &:focus {
    background-color: white;
  }
}
