.header__title {
  font-size: 28px;
  font-weight: 600;
}

.header__btnClose {
  height: 35px;
  padding: 0;
}

.header__container {
  border-bottom: 1px solid #f1f1f1;
  padding: 16px 30px;
}

.content__container {
  padding: 30px;
}

.btnSubmit {
  border-radius: 0 0 8px 8px;
  height: 60px;
}
