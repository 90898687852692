@use '../shared/scss/base/variables' as v;

.form {
  width: 100%; // Fix IE 11 issue.
}

.root {
  height: 100vh;
}

.image {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  height: 100vh;
  overflow: auto;
}

.contentHeader {
  width: 50%;
  margin: auto;
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.content {
  width: 50%;
  min-height: calc(100% - 160px);
  margin: auto;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submit {
}

.title {
  font-size: 62px;
  color: #303334;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.linkButton {
  text-transform: none;
  min-width: 160px;
  margin-left: 3px;
  margin-top: 3px;
  padding: 0 8px;
}

.registerMessage {
  margin-top: 1rem;
  p {
    font-size: 12px;
    color: v.$text-secondary;
  }
}

.setup2FaExplain {
  margin-bottom: 0.5rem;
  p {
    font-size: 12px;
    color: v.$text-secondary;
  }
}

.login2FaExplain {
  margin-bottom: 2rem;
  p {
    font-size: 12px;
  }
}

.error {
  color: #d55050;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.success {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.resetLink {
  font-size: 12px;
}

.validationError {
  color: #d55050;
  font-size: 12px;
}

.qrCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tosModal__btnClose {
  margin-top: -16px;
}

.tosModal__downloadBtn {
  min-width: 150px;
  align-self: center;
  justify-self: start;
}

.tosModal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
