.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.text {
  font-size: 20px;
  font-weight: 500;
}

.btn {
  font-size: 12px/19px;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #a4a9ab;
  height: 25px;
  border-width: 2px;
  border-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 16px;
  &:hover {
    border-width: 2px;
    border-color: #c4c4c4;
    border-radius: 3px;
  }
  &:disabled {
    color: #ebebeb;
    border-width: 2px;
    border-color: #f1f1f1;
    border-radius: 3px;
  }
}

.btn__icon {
  padding: 3px 8px;
  justify-content: start;
}

.switch__container {
  display: flex;
  height: 25px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  text-transform: capitalize;
  border: 2px solid #f1f1f1;
  border-radius: 3px;
  position: relative;
  min-width: 110px;
  max-width: 130px;
  box-sizing: border-box;
}

.switch__loader {
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  position: absolute;
  width: 100%;
  background-color: white;
  height: 100%;
}

.switch__value {
  color: #ebebeb;
  background-color: white;
  cursor: pointer;
  width: 50%;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch__selectedValue {
  color: #5a676e;
  background-color: #f1f1f1;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
}
