@use '../../scss/base/variables' as v;

.section__header {
  border-bottom: 1px solid #f1f1f1;
  padding: 20px;
}

.qna__messagesContainer {
  position: relative;
  margin-left: 34px;
  margin-right: 14px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 500px;
}

.qna__messages {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: scroll;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.qna__messages_scroll {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.qna__messages_day_group_container {
  display: flex;
  flex-direction: column;
}

.qna__messages_day_group__header {
  color: #5a676e;
  text-align: center;
  margin-bottom: 5px;
  font-size: 14px;
}

.qna__messages_day_group {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}

.qna__messageContainer {
  position: relative;
  display: flex;
  gap: 10px;
  border-radius: 16px;
  padding: 20px 10px 20px 20px;
}

.qna__messageContainer__left {
  border-bottom-left-radius: 0;
  color: #5a676e;
}

.qna__messageContainer__right {
  border-bottom-right-radius: 0;
  padding-right: 50px;
  color: #a4a9ab;
}

.qna__message_data {
  width: 80%;
}

.qna__message {
  position: relative;
  line-height: 25px;
  padding-left: 5px;
}

.qna__message_attachment {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.qna__message_attachment_name {
  margin-left: 0;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.qna_message_time {
  position: absolute;
  color: #5a676e;
  bottom: 5px;
  right: 10px;
  font-size: 13px;
}

.qna__form {
  padding-right: 20px;
}

.qna__form__input {
  display: flex;
  flex: 1;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #c4c4c4;
  border-radius: 16px;
  padding: 16px 16px 16px 0;
}

.qna__form__input__field {
  border: none;
  outline: 0;
  flex: 1;
}

.announcement__table__headerCell {
  font-size: 15px;
  font-weight: bold;
  color: v.$text-secondary;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 0;
}

.announcement__table__dateCell {
  padding-left: 20px;
  padding-right: 0;
  vertical-align: top;
  &:first-of-type {
    font-weight: 500;
  }

  div {
    display: flex;
    justify-content: space-between;
  }
}

.announcement__table__textCell {
  padding-right: 60px;
  padding-left: 20px;
  vertical-align: top;
}

.announcement__table__buttonContainer {
  display: flex;
  flex-direction: column;
}
