.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid #f1f1f1;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
}

.titleContainer {
  max-width: 470px;
}

.title {
  font-size: 28px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  button {
    margin-left: 16px;
  }
}

.expandButton {
  width: 153px;
}

.sectionContainer {
  margin-top: 45px;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 8px;
}

.sectionTitleContainer {
  display: flex;
  align-items: center;
}

.sectionTitle {
  font-size: 28px;
}

.row {
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}

.rowLabel {
  font-size: 15px;
  width: 40%;
  min-width: 100px;
}

.rowValue {
  font-size: 15px;
  font-weight: 600;
  color: #5a676e;
}

.formField {
  min-height: 32px;
  width: 216px;
  padding: 2px 0;
  display: flex;
  justify-content: center;

  input {
    padding: 8px;
  }
}

.uboSectionContainer {
  position: relative;
  margin-top: 50px;
}

.addUboButton {
  margin-top: 30px;
}

.removeUboButton {
  position: absolute;
  top: -72px;
  right: 0;
  margin-top: 30px;
}

.checkboxRow {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 10px;
}

.checkboxLabel {
  font-size: 15px;
  min-width: 100px;
  margin: auto auto auto 0;
}

.checkboxInput {
  width: 30px;
  margin-left: 120px;
}

.checkboxInfo {
  grid-column: 1 / span 2;
}

.statusBadge {
  font-weight: 600;
  margin-left: 16px;
}

.fileRow {
  display: grid;
  grid-template-columns: 45% 1fr 150px;
  column-gap: 20px;
  min-height: 52px;
  border-bottom: 1px solid #f1f1f1;
}

.fileLabel {
  font-size: 15px;
  min-width: 100px;
  justify-self: start;
  align-self: center;
}

.uploadButton,
.reUploadButton {
  width: 150px;
  grid-column-start: 3;
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
  svg {
    margin-right: 12px;
  }
}

.downloadButton {
  color: #c4c4c4;
  min-width: 150px;
  text-transform: lowercase;
  align-self: center;
  justify-self: start;
}
