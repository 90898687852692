.tabs__container {
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-bottom: 30px;
}

.tabs__tab {
  width: auto;
  font-size: 15px;
  font-weight: 600;
  border: none;
}

.tabContent {
  min-height: 200px;
  padding: 30px;
}

.accordion__title {
  font-size: 15px;
}

.loans__container {
  padding: 30px 20px;
}
