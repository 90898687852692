.section {
  margin-bottom: 40px;
}

.section__title {
  font-size: 20px;
  margin-bottom: 20px;
}

.subsection {
  margin-bottom: 20px;
}

.subsection__title {
  font-size: 15px;
  margin-bottom: 15px;
}

.fileButton {
  max-width: 100%;
}

.fileName {
  font-size: 15px;
  color: #5a676e;
  text-transform: none;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
