.container {
  margin-bottom: 20px;
}

.section__title {
  padding: 20px 20px 0;
}

.accordion__title {
  font-size: 15px;
}
