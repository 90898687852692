.main {
  margin: 30px 10%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.companyList {
  overflow-y: scroll;
  max-height: 600px;
}

.tabContent {
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 100%;
}

.tabContent__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 50px;
}

.post_conversation_form {
  display: flex;
  flex: 1;
  width: 100%;
  border-radius: 16px;
  padding: 20px;
}

.conversation_form {
  border: 1px solid #c4c4c4;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 30px;
}

.qna__form {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 20px;
}

.qna__message_attachment {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.qna__message_attachment_name {
  margin-left: 0;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.qna__form__input__field {
  border: none;
  outline: 0;
  flex: 1;
}
