@use '../../scss/base/variables' as v;

.confirmBtn {
  min-width: 106px;
}

.cancelBtn {
  min-width: 106px;
}

.modalBtn {
  border-radius: 0 0 8px 8px;
  height: 60px;
}
