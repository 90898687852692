.row {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}

.title {
  font-size: 28px;
  margin-bottom: 30px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;

  button {
    margin-right: 40px;
  }
}

.label {
  font-size: 15px;
  width: 25%;
  min-width: 100px;
}

.value {
  font-size: 15px;
  font-weight: 600;
  width: 75%;
}

.error {
  color: #d55050;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

.kycModalBox {
  padding: 1rem;
}

.kycModalTitle {
  margin-bottom: 20px;
}

.kycModalMessage {
  margin-bottom: 20px;
}

.kycModalActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  button {
    &:last-of-type {
      margin-left: auto;
    }
  }
}

.passwordModalContainer {
  padding-top: 20px;
}
