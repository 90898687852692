@use '../../../shared/scss/base/mixins' as m;

$drawer-width: 470px;
$drawer-width-small: 350px;

.drawer {
  width: $drawer-width;
  flex-shrink: 0;
  background-color: #ffffff;
  padding: 38px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  min-height: 100%;

  @include m.small-screen {
    width: $drawer-width-small;
  }
}

.drawer__paper {
  width: $drawer-width;
  padding: 38px;
  border: 1px solid #efefef;
  box-sizing: border-box;

  @include m.small-screen {
    width: $drawer-width-small;
  }
}

.drawer__button {
  background-color: #5a676e;
  width: 26px;
  height: 26px;
  color: white;
  border-radius: 5px;
  transition: none;
  &:hover {
    opacity: 0.8;
    background-color: #5a676e;
  }
}

.drawer__button__open {
  position: absolute;
  left: 455px;
  top: 95px;

  @include m.small-screen {
    left: 335px;
  }
}

.drawer__button__collapsed {
  position: absolute;
  left: 1%;
  top: 95px;
}

.content {
  flex-grow: 1;
  padding: 30px 5%;
  width: 100%;
  box-sizing: border-box;
}

.content__shifted {
  padding: 30px 3%;
  width: calc(100% - #{$drawer-width});

  @include m.small-screen {
    width: calc(100% - #{$drawer-width-small});
  }
}
