#orderbook {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  background-color: white;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
}

.orderbookTable {
  width: 100%;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: auto;
  box-sizing: border-box;
  padding: 1rem 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 16px;
}

.title {
  font-size: 28px;
}
