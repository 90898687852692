@use '../../scss/base/variables' as v;

.row {
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}

.rowLarge {
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.rowLabel {
  font-size: 15px;
  width: 40%;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.rowValue {
  font-size: 15px;
  font-weight: 600;
  color: #5a676e;
}

.formField {
  min-height: 32px;
  width: 260px;
  padding: 2px 0;
  display: flex;
  justify-content: center;

  input {
    padding: 8px;
  }
}

.countrySelect {
  padding: 6px 8px;
  width: 100%;
}

.rangePicker {
  background-color: white;
  width: 260px;
  min-height: 32px;
  height: 39px;
  border-radius: 4px;

  input {
    outline: 0;
  }

  input:invalid {
    background-color: transparent !important;
  }

  & > div:first-of-type {
    border-color: #f1f1f1;
    border-radius: 4px;
    padding-right: 2px;
    padding-left: 4px;

    & > div:nth-child(3) {
      text-align: end;
    }
  }

  .react-daterange-picker__range-divider {
  }

  .wrapper {
    border-radius: 4px;
  }
}

.rangePickerError {
  & > div:first-of-type {
    border-color: #d55050;
  }
}

.rangePickerContainer {
  display: flex;
  flex-direction: column;

  & > p {
    color: #d55050;
    font-size: 0.75rem;
    margin-left: 14px;
    margin-top: 3px;
    margin-bottom: 0;
  }
}
