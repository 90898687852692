.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 16px;
}

.title {
  font-size: 28px;
}

.table {
  margin-bottom: 30px;
}

.selectCell {
  width: 200px;
}

.selectDisplay {
  padding: 8px;
  font-weight: 500;
  width: 100%;
  text-align: left;
}

.cellValue {
  font-weight: 600;
  color: #a2aaae;
}

.modal__title {
  font-size: 28px;
  font-weight: 600;
}

.modal__content {
  padding-top: 70px;
  padding-bottom: 70px;
}

.modal__field {
  margin-top: 20px;
}

.modal__actions {
  padding: 0;
}

.modal__btnSubmit {
  border-radius: 0 0 8px 8px;
  height: 60px;
}

.modal__description {
  font-size: 12px;
  font-weight: 500;
  margin-top: 70px;
  text-align: center;
}

.modal__row {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
}

.modal__label {
  font-size: 15px;
  color: #5a676e;
  font-weight: normal;
  width: 40%;
  min-width: 100px;
}

.modal__value {
  font-size: 15px;
  color: #5a676e;
  font-weight: bold;
}

.modal_selectDisplay {
  padding: 8px;
  font-weight: 500;
  width: 200px;
  text-align: left;
}

.row {
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}

.rowLabel {
  font-size: 15px;
  width: 40%;
  min-width: 100px;
}

.rowValue {
  font-size: 15px;
  font-weight: 600;
  color: #5a676e;
}
